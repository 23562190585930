
import LoginModal from "../components/LoginModal";
import SignUpModal from "../components/SignupModal";

import { useState } from "react";

const LandingScreen = (props) => {
  const [screenMode, setScreenMode] = useState('login');

  return (
    <div className='w-full flex justify-center'>
      <div className='w-full md:w-6/12 py-24 justify-center flex'>
        {screenMode == "login" ? <LoginModal login={props.login} setSignupMode={() => setScreenMode('signup')}  /> : null}
        {screenMode == "signup" ? <SignUpModal login={props.login} setLoginMode={() => setScreenMode('login')} /> : null}
      </div>
    </div>
  );

};
export default LandingScreen;