import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  DatePicker,
  Divider,
  Button,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Spinner,
  Autocomplete,
  AutocompleteItem,
  Switch,
  Pagination
} from '@nextui-org/react';
import { Icon } from '@iconify/react';

import React from 'react';
import Alert from '../components/Alert';
import {
  useState
} from 'react';
import ApiClient from '../ApiClient';
import Screen from '../components/Screen';
import Papa from 'papaparse';
import { toast } from 'react-toastify';


const HomeScreen = (props) => {

  // Timesheet Section

  //// Inputs
  const [myfile, setMyfile] = useState(null);
  const [payperiodStartDate, setPayperiodStartDate] = useState(null);
  const [payperiodEndDate, setPayperiodEndDate] = useState(null);
  const [includeNamesDebugging, setIncludeNamesDebugging] = useState(false);

  //// Data
  const [timesheetData, setTimesheetData] = useState([]);
  const [adpEmployees, setAdpEmployees] = useState([])
  const [employeeMappings, setEmployeeMappings] = useState({})

  //// Activity
  const [saveMappingActivity, setSaveMappingActivity] = useState(false);
  

  //// Table
  const [page, setPage] = React.useState(1);
  const rowsPerPage = 10;
  const pages = Math.ceil(timesheetData.length / rowsPerPage);
  const timesheetColumns = [
    { key: 'employee', 'label': 'Employee' },
    { key: 'adp_employee', label: 'ADP Employee Regular' },
    { key: 'adp_employee_overtime', label: 'ADP Employee Overtime' },
    { key: 'holiday_hours', 'label': 'Holiday Hours' },
    { key: 'regular_hours', 'label': 'Regular Hours' }
  ]

  // Employee Section
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedEmployeeData, setSelectedEmployeeData] = useState({});


  // Employee Upload Section
  const [employeeFile, setEmployeeFile] = useState(null);
  const [uploadedEmployees, setUploadedEmployees] = useState([]);


  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return timesheetData.slice(start, end);
  }, [page, timesheetData]);

  

  const changeFileInput = (e) => {
    // using the setState method to set uploaded files to allFiles in the state object
    setMyfile(e.target.files[0]);

    const columnsMapping = {
      "employee": "employee",
      "sumofemployeeholidayhourspayable": "holiday_hours",
      "sumofemployeeregularhourspayable": "regular_hours",
    }

    const reverseColumnsMapping = {
      "employee": "Employee",
      "holiday_hours": "Sum of Employee Holiday Hours Payable",
      "regular_hours": "Sum of Employee Regular Hours Payable",
    }

    Papa.parse(e.target.files[0], {
      header: false,
      complete: function (results) {
        let headerIdentified = false;
        let headerIndex = null;
        let header = [];
        results.data.map((row, index) => {
          
          // Identify the header row
          if (!headerIdentified) {
            row.map((cell) => {
              if (cell !== null && cell !== '' && !headerIdentified) {
                headerIdentified = true;
                headerIndex = index;
              }
              if (headerIdentified && index === headerIndex) {
                let cleanedCell = cell.toLowerCase().replace(/\s/g, '');
                let colId = columnsMapping[cleanedCell];
                header.push(colId);
              }
            })
          }
        })

        // Check all required columns are present
        let requiredColumns = ['employee', 'holiday_hours', 'regular_hours'];
        requiredColumns.map((col) => {
          if (!header.includes(col)) {
            toast.error(`Missing required column: "${reverseColumnsMapping[col]}"`);
          }
        })


        // Create the data rows
          
        const data = [];
        results.data.map((row, index) => {
          if (index > headerIndex) {
            let dataRow = {};
            row.map((cell, cellIndex) => {
              dataRow[header[cellIndex]] = cell;
            })
            dataRow.index = index;
            data.push(dataRow);
          }
        })
        setTimesheetData(data);
        console.log(data);  
        }


      });
  }

  const clearTimesheetFile = () => {
    setMyfile(null);
    setTimesheetData([]);
    let fileInput = document.getElementById('timesheet-file');
    fileInput.value = null;
  }

  const getAdpEmployees = async () => {
    ApiClient.get('/api/v1/adp-upload/employee').then((response) => {
      console.log("setting!!!!");
      let options = [];
      response.data.map((employee) => {
        let data = employee
        data.label = employee.employee_name + ' (' + employee.employee_code + ')';
        data.key = employee.employee_code;
        options.push(data);
      } )
      console.log(options)
      setAdpEmployees(options);
    }).catch((error) => {
      console.log(error);
    });
  }

  const updateAdpEmployees = () => {
    ApiClient.post('/api/v1/adp-upload/employee', uploadedEmployees).then((response) => {
      toast.success("Employees uploaded successfully");
      getAdpEmployees();
      setEmployeeFile(null);
      setUploadedEmployees([]);
      let fileInput = document.getElementById('employee-file');
      fileInput.value = null;

    }).catch((error) => {
      toast.error("An error occurred while uploading employees");
    });
  }

  const handleEmployeeFileUpload = (e) => {
    let file = e.target.files[0];
    const columnsMapping = {
      "Employee Code": "employee_code",
      "Employee": "employee_name",
      "PayFrequency Code": "pay_frequency_code",
      "Employee Status": "employee_status"
    }
    Papa.parse(e.target.files[0], {
      header: false,
      complete: function (results)  {
        console.log(results);
        let headerIdentified = false;
        let headerIndex = null;
        let header = [];
        results.data.map((row, index) => {
          
          // Identify the header row
          if (!headerIdentified && index != 0) {
            row.map((cell) => {
              if (cell !== null && cell !== '' && !headerIdentified) {
                headerIdentified = true;
                headerIndex = index;
              }
              if (headerIdentified && index === headerIndex) {
                let colId = columnsMapping[cell];
                header.push(colId);
              }
            })
          }
        })

        // Create the data rows
          
        let data = [];
        results.data.map((row, index) => {
          if (index > headerIndex) {
            let dataRow = {};
            row.map((cell, cellIndex) => {
              dataRow[header[cellIndex]] = cell;
            })
            dataRow.index = index;
            data.push(dataRow);
          }
        })

        // filter all rows where employee_code is not null
        data = data.filter((row) => {
          return row.employee_code !== null && row.employee_code !== '' && row.employee_code !== undefined;
        })
        console.log("final", data);
        //updateAdpEmployees(data);
        setUploadedEmployees(data);
      }
    });
  }

  const getEmployee = (employee_id) => {
    ApiClient.get(`/api/v1/adp-upload/employee/${employee_id}`).then((response) => {
      setSelectedEmployeeData(response.data);
    }).catch((error) => {
      toast.error("An error occurred while fetching employee data");
    });
  }

  const selectedEmployeeChange = (employee) => {
    setSelectedEmployee(employee);
    getEmployee(employee);
  }


  React.useEffect(() => {
    getAdpEmployees()
    getEmployeeMappings()
  }, []);



  const downloadOutputFile = async () => {
    try {
      await getAdpEmployees();
      const data = []
      const employee_lookup = {};
      adpEmployees.map((employee) => {
        employee_lookup[employee.key] = employee
      })

      timesheetData.map((row) => {
        let mapping = employeeMappings[row.employee];
        if (mapping && mapping.employee_code_regular) {
          let base_record = {
            'IID': 23692439,
            'PayFrequency': 'W',
            'Pay Period Start': payperiodStartDate,
            'Pay Period End': payperiodEndDate,
            //'Earnings Code': 'REG',
            //'Pay Hours': row.regular_hours,
            'Dollars': '',
            'Separate Check': 0,
            'Department Number': '',
            'Rate Code': 'BASE',
            'Job Code': '',
          }

          if (row.holiday_hours > 0) {
            let holiday_record = { ...base_record };
            let employee_code = mapping.employee_code_regular;
            holiday_record['Employee ID'] = mapping.employee_code_regular;
            holiday_record['Pay Hours'] = row.holiday_hours;
            holiday_record['Earnings Code'] = 'HOL';
            if (includeNamesDebugging) {
              holiday_record['Employee Name'] = employee_lookup[employee_code].employee_name;
            }
            data.push(holiday_record);
          }
          if (row.regular_hours > 40) {
            let employee_code = mapping.employee_code_overtime ? mapping.employee_code_overtime : mapping.employee_code_regular;
            let overtime_record = { ...base_record };
            overtime_record['Employee ID'] = (mapping.employee_code_overtime ? mapping.employee_code_overtime : mapping.employee_code_regular);
            let hours = row.regular_hours - 40;
            overtime_record['Pay Hours'] = employee_lookup[employee_code].employee_type == 'CONTRACTOR' ? (hours * 1.5) : hours;
            overtime_record['Earnings Code'] = employee_lookup[employee_code].employee_type == 'CONTRACTOR' ? '1099' : "OVT";
            if (includeNamesDebugging) {
              overtime_record['Employee Name'] = employee_lookup[employee_code].employee_name;
            }
            data.push(overtime_record);
          }
          if (row.regular_hours > 0) {
            let regular_record = { ...base_record };
            let employee_code = mapping.employee_code_regular;
            console.log(employee_code);
            console.log(employee_lookup[employee_code])
            regular_record['Employee ID'] = mapping.employee_code_regular;
            regular_record['Pay Hours'] = Math.min(row.regular_hours, 40);
            regular_record['Earnings Code'] = employee_lookup[employee_code].employee_type == 'CONTRACTOR' ? '1099' : "REG";
            if (includeNamesDebugging) {
              regular_record['Employee Name'] = employee_lookup[employee_code].employee_name;
            }
            data.push(regular_record);
          }
        }
        
      })


      let fields = [
        'IID',
        'PayFrequency',
        'Pay Period Start',
        'Pay Period End',
        'Employee ID',
        'Earnings Code',
        'Pay Hours',
        'Dollars',
        'Separate Check',
        'Department Number',
        'Rate Code',
        'Job Code',
      ]

      if (includeNamesDebugging) {
        fields.push('Employee Name');
      }

      const csv = Papa.unparse({
        data: data,
        fields: fields
      });
      const csvWithHeader = '##GENERIC## V2.0\n' + csv;
      const blob = new Blob([csvWithHeader], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'output.csv');
      document.body.appendChild(link);
      link.click();

    } catch (error) {
      console.log()
      console.log(error);
      toast.error("An error occurred while generating the output file");
    }
    
  }

  const getEmployeeMappings = () => {
    ApiClient.get('/api/v1/adp-upload/mapping').then((response) => {
      console.log(response);
      setEmployeeMappings(response.data);
    }).catch((error) => {
      console.log(error);
    });
  }

  const updateEmployeeMapping = (employee, adp_employee, type) => {
    console.log("updateEmployeeMapping");
    let mappings = { ...employeeMappings };
    
    if (!mappings[employee]) {
      console.log('creating new mapping');
      mappings[employee] = {};
    }
    if (type === 'regular') {
      console.log('updating regular');
      mappings[employee].employee_code_regular = adp_employee;
    }
    if (type === 'overtime') {
      console.log('updating overtime');
      mappings[employee].employee_code_overtime = adp_employee;
    }
    mappings[employee].updated = true;
    setEmployeeMappings(mappings);
  }

  const saveMappings = () => {
    setSaveMappingActivity(true);
    let mappings = [];
    Object.keys(employeeMappings).map((employee) => {
      let mapping = employeeMappings[employee];
      if (mapping.updated) {
        mappings.push({
          employee_name_ms: employee,
          employee_code_regular: mapping.employee_code_regular,
          employee_code_overtime: mapping.employee_code_overtime
        });
      }
    })
    ApiClient.post('/api/v1/adp-upload/mapping', mappings).then((response) => {
      toast.success("Mappings saved successfully");
    }).catch((error) => {
      toast.error("An error occurred while saving mappings");
    }).finally(() => {
      setSaveMappingActivity(false);
    });
  }

  const saveEmployee = () => {
    ApiClient.post(`/api/v1/adp-upload/employee/${selectedEmployee}`, selectedEmployeeData).then((response) => {
      toast.success("Employee data saved successfully");
    }).catch((error) => {
      toast.error("An error occurred while saving employee data");
    });
  }



  return (
    <Screen>
      <h1 className='m-8 text-4xl'>ADP Upload</h1>
      <Card className='m-8'>
        <CardHeader>
          <h1 className='text-xl'>Timesheet File</h1>
        </CardHeader>
        <Divider />
        <CardBody>
          <Alert type='info'>
            <p>Please Upload a csv file containing the timesheet information</p>
            <p>File should contain the following columns: Employee, Sum of Employee Holiday Hours Payable, Sum of Employee Regular Hours Payable</p>
            <p>Select the start and end date of the pay period</p>
            <p>Scan the table below and ensure that at least all rows have a value selected for "ADP Employee Regular".  Anyone without this field will be missing from the final output.</p>
            <br/>
            <p>If new employees have been created since the last time running payroll, export the "Employee Codes" report from ADP.  Save that report as a CSV and upload that file to in the ADP Employee Upload section below.</p>
            <p>For each newly uploaded employee, use the ADP Employees sections to pull up each one, and specify if they are an Employee or a Contractor</p>
            <br/>
            <p>Scan the table once more for missing mappings, Save Mappings if you have made any changes, then click "Generate File".  The CSV that downloads can be uploaded directly to ADP</p>
          </Alert>
            <div className='flex flex-row'>
              {myfile != null ? <Button className='mr-2' isIconOnly onClick={clearTimesheetFile} variant='flat' color='danger' ><Icon icon="ph:trash" height={15} color="red" /></Button> : null}
              <input id="timesheet-file" type="file" onChange={changeFileInput} disabled={myfile != null ? true : false } />
            </div>
            
            <div className='flex flex-row mt-4'>
            <DatePicker
              className='w-52'
              label="Pay Period Start Date"
              value={payperiodStartDate}
              onChange={setPayperiodStartDate}
            />
            <DatePicker
              className='w-52 ml-4'
              label="Pay Period End Date"
              value={payperiodEndDate}
              onChange={setPayperiodEndDate}
            />
            <Switch className='ml-4' label="Include Overtime" size="sm" isSelected={includeNamesDebugging} onChange={() => {setIncludeNamesDebugging(!includeNamesDebugging); console.log(includeNamesDebugging)}}>
              Include Names in Debugging
            </Switch>
          </div>
          <Table removeWrapper className="mt-4"
            bottomContent={
              <div className="flex w-full justify-center">
                <Pagination
                  isCompact
                  showControls
                  showShadow
                  color="black"
                  page={page}
                  total={pages}
                  onChange={(page) => setPage(page)}
                />
              </div>
            }
            >
          <TableHeader columns={timesheetColumns}>
              {(column) => <TableColumn key={column.key} allowsSorting={true}>
                {column.label}
              </TableColumn>}
          </TableHeader>
          <TableBody items={items}>
              {
                items.map((item) => {

                  return (
                    <TableRow key={item.index}>
                  <TableCell>{item.employee}</TableCell>
                  <TableCell>
                  <Autocomplete 
                  aria-label="item"
                    className="max-w-xs" 
                    selectedKey={employeeMappings[item.employee] ? employeeMappings[item.employee].employee_code_regular : null}
                    onSelectionChange={(value) => updateEmployeeMapping(item.employee, value, 'regular')}
                  >
                    {adpEmployees.map((col) => {
                      return (
                        <AutocompleteItem key={col.key}>
                          {col.label}
                        </AutocompleteItem>
                      )
                    })}
                  </Autocomplete>
                  </TableCell>
                  <TableCell>
                  <Autocomplete 
                   aria-label="item"
                    className="max-w-xs" 
                    selectedKey={employeeMappings[item.employee] ? employeeMappings[item.employee].employee_code_overtime : null}
                    onSelectionChange={(value) => updateEmployeeMapping(item.employee, value, 'overtime')}
                  >
                    {adpEmployees.map((col) => {
                      return (
                        <AutocompleteItem key={col.key}>
                          {col.label}
                        </AutocompleteItem>
                      )
                    })}
                  </Autocomplete>
                  </TableCell>
                  <TableCell>{item.holiday_hours}</TableCell>
                  <TableCell>{item.regular_hours}</TableCell>


              </TableRow>
                  )

                })
              }
                
              
          </TableBody>
          </Table>
        </CardBody>
        <Divider />
        <CardFooter className='justify-end'> 
          <div className=''>
            <Button onClick={saveMappings} className='bg-black text-white mr-2' >
              { saveMappingActivity ? <Spinner size="sm" color="default" /> :  "Save Mapping" }
            </Button>
            <Button onClick={downloadOutputFile} className='bg-black text-white' >Generate File</Button>
          </div>
        </CardFooter>
      </Card>
      <Card className='m-8'>
        <CardHeader>
          <h1 className='text-xl'>ADP Employees</h1>
        </CardHeader>
        <Divider />
        <CardBody>
        <Autocomplete 
            label="Select and Employee"
             className="max-w-xs" 
            selectedKey={selectedEmployee}
            onSelectionChange={(value) => selectedEmployeeChange(value)}
          >
            {adpEmployees.map((col) => {
              return (
                <AutocompleteItem key={col.key}>
                  {col.label}
                </AutocompleteItem>
              )
            })}
            
          </Autocomplete>
          <Autocomplete
            className='mt-4'
            label="Employee Type"
            selectedKey={selectedEmployeeData ? selectedEmployeeData.employee_type: null}
            onSelectionChange={(value) => {let data = { ...selectedEmployeeData }; data.employee_type = value; setSelectedEmployeeData(data)}}
            >
            <AutocompleteItem key="EMPLOYEE">Employee</AutocompleteItem>
            <AutocompleteItem key="CONTRACTOR">Contractor</AutocompleteItem>
          </Autocomplete>
        </CardBody>
        <Divider />
        <CardFooter className='justify-end'> 
          <div className=''>
            <Button onClick={() => saveEmployee()} className='bg-black text-white' >Save</Button>
          </div>
        </CardFooter>
      </Card>
      <Card className='m-8'>
        <CardHeader>
          <h1 className='text-xl'>ADP Employee Upload</h1>
        </CardHeader>
        <Divider />
        <CardBody>
        <input type="file" id="employee-file" onChange={handleEmployeeFileUpload}/>
        {
          uploadedEmployees.length > 0 ?
          <h1 className='mt-4'>Click upload to submit data for {uploadedEmployees.length} employees.</h1> :
          null
        }
        </CardBody>
        <Divider />
        <CardFooter className='justify-end'> 
          <div className=''>
            <Button onClick={() => updateAdpEmployees()} className='bg-black text-white' >Upload</Button>
          </div>
        </CardFooter>
      </Card>
    </Screen>
  );

};
export default HomeScreen;