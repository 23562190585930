import React from "react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {writeUserData} from '../utils/auth';
import {Modal, Spinner, Image, ModalBody, ModalFooter, Button, Divider, Checkbox, Input, Card, CardBody, CardHeader, CardFooter} from "@nextui-org/react";
import ApiClient from '../ApiClient';
import SteadFastLogo from "../assets/logohor-stead.png";
import {
    TbMail,
    TbLock,
  } from 'react-icons/tb';
import {
  Icon
} from '@iconify/react';


export default function LoginModal(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginActivity, setLoginActivity] = useState(false);
    const [showPasswordResetMessage, setShowPasswordResetMessage] = useState(false);
    const [showLoginError, setShowLoginError] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState([]);

    const navigate = useNavigate();

    const login = () => {
      setLoginActivity(true);
      ApiClient.post('/api/v1/auth/login', {
        email: email,
        password: password
      }).then((response) => {
        writeUserData(response.data);
        props.login();
        navigate('/');
      }).catch((error) => {
        setShowLoginError(true);
        setLoginErrorMessage([error.response.data.error]);
      }).finally(() => {
        setLoginActivity(false);
      });
    }

    const resetPassword = () => {
      ApiClient.post('/api/v1/auth/reset-password', {
        email: email
      }).then((response) => {
        console.log(response.data);
        setShowPasswordResetMessage(true);
      }).catch((error) => {
        console.log(error);
      });
    }

  return (
    <>
      <Card 
        placement="top-center"
        className="max-w-md w-full"
      >
              <CardHeader className="flex flex-col gap-1 p-8 px-16 bg-black"><Image src={SteadFastLogo}></Image></CardHeader>
              <CardBody className="p-6">
                <Input
                  className="mb-4"
                  autoFocus
                  endContent={
                    <TbMail className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                  }
                  label="Email"
                  placeholder="Enter your email"
                  variant="flat"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                className="mb-2"
                  endContent={
                    <TbLock className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                  }
                  label="Password"
                  placeholder="Enter your password"
                  type="password"
                  variant="flat"
                  onChange={(e) => setPassword(e.target.value)}
                />

                  <p className="cursor-pointer mb-4 text-gray-400 ml-1 text-sm" onClick={() => resetPassword()}>forgot password</p>
                  <Button className="bg-black text-white" variant="flat" onClick={() => login()} disabled={loginActivity}>
                  { loginActivity ? <Spinner size="sm" color="default" /> :  "Sign in" }
                </Button>
                  <p className="text-center mt-8 text-sm">Don't have an account yet? <span onClick={props.setSignupMode} className="cursor-pointer underline">Sign Up</span> here.</p>
                  {
                    showPasswordResetMessage ? <p className="text-red-500 mt-4 text-center">Password reset email sent.</p> : null
                  }
                  {
                    showLoginError ? (
                      <div className="bg-red-100 text-red-600 p-2 rounded my-2">
                        {
                          loginErrorMessage.map((error, index) => (
                            <p key={index}>{error}</p>
                          ))
                        }
                      </div>
                    ) : null
                }
              

              </CardBody>
              

              <CardFooter>
                
                
              </CardFooter>
      </Card>
    </>
  );
}
