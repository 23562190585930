// App.tsx or App.jsx
import {BrowserRouter ,Route, Routes} from 'react-router-dom';
import {NextUIProvider} from '@nextui-org/react';
import AccountsScreen from './screens/HomeScreen';
import LandingScreen from './screens/LandingScreen';
import PasswordResetScreen from './screens/PasswordResetScreen';
import Sidebar from './components/Sidebar';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from "@sentry/react";
import './App.css';

Sentry.init({
  dsn: "https://6ed0504a0245ec79d040eb5eb0c511e8@o4505522458198016.ingest.us.sentry.io/4508078576566272",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: window.location.hostname == "steadfast-security.asynctech.app" ? "production" : "development",
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});




function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});

  const logout = () => {
    axios.post('/api/v1/auth/logout').then((response) => {
      setUser({})
      setIsLoggedIn(false);
      window.location.replace("/");
    }).catch((error) => {
      console.log(error);
    });
    
  }

  const login = () => {
    fetchUserData();
  }

  const fetchUserData = () => {
    axios.get('/api/v1/user').then((response) => {
      setUser(response.data);
      setIsLoggedIn(true);
    }).catch((error) => {
      setIsLoggedIn(false);
      setUser({});
      //window.location.replace("/welcome");
    });
  }

  useEffect(() => {
      fetchUserData();
  }, []);

  const sidebarItems = {
    tasks: {
      icon: "teenyicons:money-outline",
      title: "ADP Upload",
      path: "/",
      element: <AccountsScreen />
    }
  }

  return (
    <NextUIProvider>
      <BrowserRouter>
      <Sidebar logout={logout} isLoggedIn={isLoggedIn} user={user} login={login} sidebarItems={sidebarItems}>
        <ToastContainer />
      <Routes>

        {
          user.user_id ==  undefined ? <Route path="/" element={<LandingScreen logout={logout} isLoggedIn={isLoggedIn} user={user} login={login} />} /> : null
        }
        
        {
          user.user_id !== undefined ? 
          Object.keys(sidebarItems).map((key) => {
            return <Route key={key} path={sidebarItems[key].path} element={sidebarItems[key].element} />
          })
          : null
        }
      <Route path="/password-reset" element={<PasswordResetScreen />} />
      </Routes>
      </Sidebar>
      </BrowserRouter>
    </NextUIProvider>
  );
}


export default App;