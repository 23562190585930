

import {Avatar, Divider, Listbox, ListboxItem, Tooltip, Popover, PopoverTrigger, PopoverContent,} from "@nextui-org/react";
import React from "react";
import { Icon } from '@iconify/react';
import AsyncTechLogo from "../assets/asynctech.png";
import {useNavigate} from "react-router-dom";
import SteadfastIcon from '../assets/steadfast-logo.png';
import SteadfastFullLogo from '../assets/logohor-stead.png';

const sidebarItems = {
  dashboard: {
    icon: "tabler:dashboard",
    title: "Dashboard"
  },
  projects: {
    icon: "tabler:check",
    title: "Projects"
  },
  customers: {
    icon: "tabler:users",
    title: "Customers"
  },
  tasks: {
    icon: "tabler:list-check",
    title: "Tasks"
  }
}

const Sidebar = (props) => {
    const [selectedKeys, setSelectedKeys] = React.useState(new Set(["text"]));
    const [showFullSidebar, setShowFullSidebar] = React.useState(true);
    const navigate = useNavigate();

    const selectedValue = React.useMemo(
        () => Array.from(selectedKeys).join(", "),
        [selectedKeys]
      );
    
    const name = (
      <div className="flex flex-col ml-3">
        <p className="text-sm">{props.user.email}</p>
        <p className="text-xs text-gray-500">Admin</p>
      </div>
    )

    return (
      <>
        {
          props.user.user_id ? (
             <div className="flex flex-row">
              <div className={"relative flex h-full flex-col border-r-small border-divider h-lvh justify-between " + (showFullSidebar ? "w-80" : "w-20")} style={{}}>
                <div 
                  onClick={() => setShowFullSidebar(!showFullSidebar)}
                  className="rounded-full w-6 h-6 border-light-grey border-1 bg-white absolute -right-3 top-6 flex items-center justify-center cursor-pointer"
                  >
                  <Icon icon="icon-park-outline:left" height={15} color="grey" />
                </div>
                <div>
                  <div className={"p-4 bg-black flex flex-row items-center " + (!showFullSidebar ? "justify-center" : "") }>
                    
                    {
                      showFullSidebar ?
                      <img src={SteadfastFullLogo} width={130} /> :
                       <div className="p-1  rounded">
                        <img src={SteadfastIcon} width={32} />
                      </div>
                    }
                  </div>
                  <Divider/>
                </div>
                <div className="overflow-y-scroll h-full no-scrollbar">
                  <Listbox 
                    className={showFullSidebar ? "p-4" : "p-1"}
                    aria-label="Single selection example"
                    variant="flat"
                    hideSelectedIcon={true}
                    disallowEmptySelection
                    selectionMode="single"
                    selectedKeys={["tasks"]}
                    onSelectionChange={setSelectedKeys}
                  >
                  {
                      Object.keys(props.sidebarItems).map((item) => (
                        
                        <ListboxItem key={item} onClick={() => navigate(props.sidebarItems[item].path)}>
                          <Tooltip key={item} placement="right" content={props.sidebarItems[item].title} offset={20} isDisabled={showFullSidebar}>
                          <div className={"flex flex-row items-center " + (!showFullSidebar ? "justify-center" : "")}>
                          <Icon icon={props.sidebarItems[item].icon} className="flex" height={24}  />
                          { showFullSidebar ? <p className="ml-2 ">{props.sidebarItems[item].title}</p> : null}
                          </div>
                          </Tooltip>
                        </ListboxItem>
                      ))
                    
                  }
                  </Listbox>
                </div>
                <div>
                <Divider/>
                <div className={"p-4 flex flex-row " + (!showFullSidebar ? "justify-center" : "")}>
                  <Popover key={"avatar-popover"} placement={"top-end"}>
                  <PopoverTrigger>
                  <Avatar isBordered radius="full"/>
                  </PopoverTrigger>
                  <PopoverContent>
                    <Listbox>
                        <ListboxItem key="logout" onClick={props.logout}>Logout</ListboxItem>
                    </Listbox>
                  </PopoverContent>
                  </Popover>
                  {showFullSidebar ? name : null}
                </div>
              </div>
            </div>
            <div className="w-full">
            {props.children}
            </div>
          </div>
        ):
        <div>{props.children}</div>
        }
      </>
    );
  
  };
  export default Sidebar;