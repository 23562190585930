import React from "react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {writeUserData} from '../utils/auth';
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Checkbox, Input, Card, CardBody, CardHeader, CardFooter} from "@nextui-org/react";
import axios from 'axios';
import {
    TbMail,
    TbLock,
  } from 'react-icons/tb';

export default function LoginModal(props) {
    const [password, setPassword] = useState('');
    const [loginActivity, setLoginActivity] = useState(false);

    const navigate = useNavigate();

    const reset = () => {
        axios.post('/api/v1/auth/reset-password/' + props.email, { token: props.token, password: password }).then((response) => {
            axios.post('/api/v1/auth/login', {
                email: props.email,
                password: password
            }).then((response) => {
                window.location.replace("/");
            }).catch((error) => {
                console.log(error);
            });
        }).catch((error) => {
            console.log(error);
        });
    };


  return (
    <>
      <Card 
        placement="top-center"
      >
              <CardHeader className="flex flex-col gap-1">Log in</CardHeader>
              <CardBody className="gap-6">
                <Input
                  autoFocus
                  endContent={
                    <TbMail className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                  }
                  label="Email"
                  placeholder="Enter your email"
                  disabled
                  variant="flat"
                  value={props.email}
                  defaultValue={props.email}
                />
                <Input
                  endContent={
                    <TbLock className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                  }
                  label="Password"
                  placeholder="Enter your password"
                  type="password"
                  variant="flat"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </CardBody>

              <CardFooter>
                <Button color="primary" variant="flat" onClick={() => reset()} disabled={loginActivity}>
                  Reset Password
                </Button>
              </CardFooter>
      </Card>
    </>
  );
}
