import React from "react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Image, Checkbox, Input, Link, Card, CardBody, CardFooter, CardHeader} from "@nextui-org/react";
import ApiClient from '../ApiClient';
import {
    TbMail,
    TbLock,
  } from 'react-icons/tb';
import SteadFastLogo from "../assets/logohor-stead.png";


export default function SignUpModal(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [validated, setValidated] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);
    const [showValidationErrors, setShowValidationErrors] = useState(false);
    const [showWelcome, setShowWelcome] = useState(false);
    
    const [loginActivity, setLoginActivity] = useState(false);

    const navigate = useNavigate();

    const signUp = () => {
      setLoginActivity(true);
      ApiClient.post('/api/v1/auth/signup', {
        email: email,
        password: password
      }).then((response) => {
        setShowWelcome(true);
      }).catch((error) => {
        console.log(error);
        setShowValidationErrors(true);
        setValidationErrors([error.response.data.error]);
      }).finally(() => {
        setLoginActivity(false);
      });
    }

    const validate = () => {
      let errors = [];
      if (email === '') {
        errors.push('Email is required');
      }
      if (password === '') {
        errors.push('Password is required');
      }
      if (password2 === '') {
        errors.push('Re-enter password is required');
      }
      if (password !== password2) {
        errors.push('Passwords do not match');
      }
      setValidationErrors(errors);
      if (errors.length === 0) {
        setValidated(true);
        setShowValidationErrors(false);
        return true;
      } else {
        setValidated(false);
        setShowValidationErrors(true);
        return false;
      }
    }

    const validatedSignup = () => {
      if (validate()) {
        signUp();
      }
    }


  return (
    <>
      <Card 
        placement="top-center"
        className="max-w-md w-full"
      >
              <CardHeader className="flex flex-col gap-1 p-8 px-16 bg-black"><Image src={SteadFastLogo}></Image></CardHeader>
              {
                showWelcome ? (
                  <CardBody>
                    <p className="mt-4 text-center">Thank you for signing up. Please <span onClick={props.setLoginMode} className="cursor-pointer underline">login</span> to get started.</p>
                  </CardBody>
                ) : null
              }
              {
                !showWelcome ?
                <CardBody className="p-6">
                <Input
                  autoFocus
                  endContent={
                    <TbMail className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                  }
                  label="Email"
                  placeholder="Enter your email"
                  variant="flat"
                  onChange={(e) => setEmail(e.target.value)}
                  className="mb-4"
                />
                <Input
                  endContent={
                    <TbLock className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                  }
                  label="Password"
                  placeholder="Enter your password"
                  type="password"
                  variant="flat"
                  onChange={(e) => setPassword(e.target.value)}
                  className="mb-4"
                />
                <Input
                  endContent={
                    <TbLock className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                  }
                  label="Re-enter Password"
                  placeholder="Enter your password again"
                  type="password"
                  variant="flat"
                  onChange={(e) => setPassword2(e.target.value)}
                  className="mb-4"
                />
                {
                    showValidationErrors ? (
                      <div className="bg-red-100 text-red-600 p-2 rounded my-2">
                        {
                          validationErrors.map((error, index) => (
                            <p key={index}>{error}</p>
                          ))
                        }
                      </div>
                    ) : null
                }
                {/*
                <div className="flex py-2 px-1 justify-between">
                  <Checkbox
                    classNames={{
                      label: "text-small",
                    }}
                  >
                    Remember me
                  </Checkbox>
                  <Link color="primary" href="#" size="sm">
                    Forgot password?
                  </Link>
                </div>
                  */}
              <Button className="bg-black text-white" variant="flat" onClick={() => validatedSignup()} disabled={loginActivity}>
                  Sign Up
                </Button>
              <p className="text-center mt-8 text-sm">Already have an account? <span onClick={props.setLoginMode} className="cursor-pointer underline">Login</span> here.</p>

              </CardBody>
                :null
              }
               <CardFooter>
                
                
                </CardFooter>
      </Card>
    </>
  );
}
