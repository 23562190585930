import axios from 'axios';
import { readUserData, deleteUserData } from "./utils/auth";


// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Add Token to all requests
    let user = readUserData();
    if (user) {
        config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });


// Add a response interceptor
axios.interceptors.response.use(function (response) {

    return response;
  }, function (error) {
    // Do something with response error
    if (error.response.status === 401) {
      if (window.location.pathname !== "/welcome") {
        window.location.replace("/welcome");
      }
    }
    return Promise.reject(error);
  });

export default axios;