
import PasswordResetModal from "../components/PasswordResetModal";

import { useState } from "react";
import {
    useSearchParams
} from "react-router-dom";

const PasswordResetScreen = (props) => {
  const [screenMode, setScreenMode] = useState('login');
  const [searchParams, setSearchParams] = useSearchParams();

  console.log(searchParams);

  return (
    <div className='w-full flex justify-center'>
      <div className='w-full md:w-6/12 py-24'>
        <PasswordResetModal email={searchParams.get("email")} token={searchParams.get("token")} />
      </div>
    </div>
  );

};
export default PasswordResetScreen;